<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.ecommerce.order.save.newOrder') }}
        </h2>
        <template v-if="!$root.filterWithUrl">
            <router-link to="/ecommerce/user" class="btn btn-primary align-self-center" v-if="userID > 0">
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
                </span>
                {{ $t("menu.users") }}
            </router-link>
            <router-link to="/ecommerce/order-management/order" class="btn btn-primary align-self-center" v-else>
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
                </span>
                {{ $t("menu.orders") }}
            </router-link>
        </template>
    </div>
    <el-form :model="form.data" ref="orderForm">
        <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
            <div class="col-md-8">
                <div class="card card-flush pt-3 pb-3 mb-5 mb-lg-10">
                    <div class="card-header">
                        <div class="card-title">
                            <h2 class="fw-bolder">{{ $t('pages.ecommerce.order.save.product.title') }}</h2>
                        </div>

                        <div class="card-toolbar">
                            <button type="button" class="btn btn-light-primary" v-on:click="newProduct">{{ $t('pages.ecommerce.order.save.product.addProduct') }}</button>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column p-9" v-if="form.data.items.length">
                        <div class="table-responsive">
                            <table class="table align-middle table-row-dashed fs-6 fw-bold gy-4">
                                <thead>
                                <tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                    <th class="min-w-300px">{{ $t('pages.ecommerce.order.save.product.cols.title') }}</th>
                                    <th class="min-w-100px">{{ $t('pages.ecommerce.order.save.product.cols.quantity') }}</th>
                                    <th class="min-w-70px text-end">{{ $t('common.action') }}</th>
                                </tr>
                                </thead>

                                <tbody class="text-gray-600">
                                <tr v-for="(item, itemIndex) in form.data.items" :key="itemIndex" v-bind:class="[itemIndex % 2 == 0 ? 'odd' : 'event']">
                                    <td>{{ item.title }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td class="text-end">
                                        <a class=" btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3" v-on:click="form.data.items.splice(itemIndex, 1)">
                                            <span class="svg-icon svg-icon-3">
                                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                            </span>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.save.cols.user') }}</label>
                            <el-form-item prop="user_id" :rules="$validation.getMessage(['required'])">
                                <el-select
                                    v-model="form.data.user_id"
                                    class="w-100"
                                    :filterable="user.filterable"
                                    remote
                                    :remote-method="remoteSearchUser"
                                    @change="selectedUser"
                                    :loading="user.loading"
                                    :placeholder="$t('pages.ecommerce.order.save.userSearch')">
                                    <el-option v-for="(user, userIndex) in user.list" :key="userIndex" :value="user.id" :label="user.full_name + ' (' + user.email +')'"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1" v-if="form.data.user_id">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.save.cols.userAddress') }}</label>
                            <el-form-item prop="user_address_id">
                                <el-select v-model="form.data.user_address_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                    <el-option v-for="(userAddress, userAddressIndex) in user.addressList" :key="userAddressIndex" :value="userAddress.id" :label="userAddress.title"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.save.cols.orderStatus') }}</label>
                            <el-form-item prop="order_status_id" :rules="$validation.getMessage(['required'])">
                                <el-select v-model="form.data.order_status_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                    <el-option v-for="(status, statusIndex) in orderStatus" :key="statusIndex" :value="status.id" :label="status.name">{{ status.name }}</el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0">
                                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
    <div class="modal fade" id="kt_modal_add_product" ref="addProductModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ productForm.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitProduct()" :model="productForm.data" ref="productForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.save.product.cols.title') }}</label>
                                <el-form-item prop="id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="productForm.data.id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable @change="selectedProduct">
                                        <el-option v-for="(product, productIndex) in products" :disabled="!(product.stock > product.sold_out_count)" :key="productIndex" :value="product.id" :label="selectProductLabel(product)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.save.product.cols.quantity') }}</label>
                                <el-form-item prop="quantity" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="productForm.data.quantity" :min="1"/>
                                </el-form-item>
                            </div>
                            <div class="row" v-if="productForm.currentProduct.type_id && productForm.currentProduct.type_id == 2">
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold">{{ $t('pages.ecommerce.order.save.product.cols.usageMinDate') }}</label>
                                    <div class="fs-7 text-gray-800 mb-2" v-if="productForm.currentProduct.usage_min_date_initial"><b>{{ $t('common.system')}}:</b> {{ $moment(productForm.currentProduct.usage_min_date_initial).format("DD.MM.YYYY - HH:mm:ss") }}</div>
                                    <el-form-item prop="usage_min_date" class="mb-md-0 mb-2 mt-2" v-if="!productForm.data.usage_min_date_check">
                                        <el-date-picker
                                            class="rangeDateTimePicker"
                                            v-model="productForm.data.usage_min_date"
                                            type="datetime"
                                            popper-class="rangeDateTimePickerPopper"
                                            :placeholder="$t('common.chooseDateAndTime')"
                                            valueFormat="YYYY-MM-DD HH:mm:ss"
                                            :disabledDate="(startDate) => disabledUsageMinDate(productForm.data.usage_max_date, startDate)">
                                        </el-date-picker>
                                    </el-form-item>
                                    <div class="mt-2 mb-1">
                                        <div class="form-check form-check-custom form-check-solid">
                                            <input class="form-check-input" type="checkbox" v-model="productForm.data.usage_min_date_check" id="productUsageMinDate">
                                            <label class="form-check-label" for="productUsageMinDate">
                                                {{ $t('pages.ecommerce.order.save.product.usageMinDateCheckText') }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 fv-row mb-1">
                                    <label class="fs-6 fw-bold">{{ $t('pages.ecommerce.order.save.product.cols.usageMaxDate') }}</label>
                                    <div class="fs-7 text-gray-800 mb-2" v-if="productForm.currentProduct.usage_max_date_initial"><b>{{ $t('common.system')}}:</b> {{ $moment(productForm.currentProduct.usage_max_date_initial).format("DD.MM.YYYY - HH:mm:ss") }}</div>
                                    <el-form-item prop="usage_max_date" class="mb-0 mt-2" v-if="!productForm.data.usage_max_date_check">
                                        <el-date-picker
                                            class="rangeDateTimePicker"
                                            v-model="productForm.data.usage_max_date"
                                            type="datetime"
                                            popper-class="rangeDateTimePickerPopper"
                                            :placeholder="$t('common.chooseDateAndTime')"
                                            valueFormat="YYYY-MM-DD HH:mm:ss"
                                            :disabledDate="(endDate) => disabledUsageMaxDate(productForm.data.usage_min_date, endDate)">
                                        </el-date-picker>
                                    </el-form-item>
                                    <div class="mt-2 mb-1">
                                        <div class="form-check form-check-custom form-check-solid">
                                            <input class="form-check-input" type="checkbox" v-model="productForm.data.usage_max_date_check" id="productUsageMaxDate">
                                            <label class="form-check-label" for="productUsageMaxDate">
                                                {{ $t('pages.ecommerce.order.save.product.usageMaxDateCheckText') }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-10" v-if="productForm.addableAddons.length || productForm.data.addons.length">
                                <el-divider content-position="center" class="mb-10">{{ $t('pages.ecommerce.order.save.product.addon.title') }}</el-divider>

                                <div class="fv-row mb-7" v-if="productForm.addableAddons.length">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.save.product.addon.addableAddons') }}</label>
                                    <el-select class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(addableAddon, addableAddonIndex) in productForm.addableAddons" :key="addableAddonIndex" :value="addableAddonIndex" :label="addableAddon.title" @click="selectedAddableAddon(addableAddonIndex)"></el-option>
                                    </el-select>
                                </div>

                                <div class="fv-row mb-3" v-for="(addon, addonIndex) in productForm.data.addons" :key="addonIndex">
                                    <div class="d-flex align-items-center mb-2">
                                        <label class="fs-6 fw-bold">{{ sprintf($t('pages.ecommerce.order.save.product.addon.addonPattern'), [(addonIndex + 1), addon.title]) }}</label>
                                        <a class="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px ms-3" v-on:click="removeAddableAddon(addonIndex)" v-if="addon.removable">
                                            <span class="svg-icon svg-icon-3">
                                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                            </span>
                                        </a>
                                    </div>
                                    <el-input-number v-model="productForm.data.addons[addonIndex].quantity" :min="1"/>
                                </div>
                            </div>
                            <div class="mt-10" v-if="Object.keys(productForm.packageItemsAddons).length">
                                <el-divider content-position="center" class="mb-10">{{ $t('pages.ecommerce.order.save.product.packageItem.title') }}</el-divider>
                                <div class="mt-8" v-for="(packageItem, packageItemID, packageItemIndex) in productForm.packageItemsAddons" :key="packageItemIndex">
                                    <h4 class="fw-bolder">{{ sprintf($t('pages.ecommerce.order.save.product.packageItem.packageItemPattern'), [(packageItemIndex + 1), packageItem.title]) }}</h4>

                                    <div class="row mb-7" v-if="productForm.currentPackageItems[packageItemID] && productForm.currentPackageItems[packageItemID].type_id && productForm.currentPackageItems[packageItemID].type_id == 2">
                                        <div class="col-md-6 fv-row mb-1">
                                            <label class="fs-6 fw-bold">{{ $t('pages.ecommerce.order.save.product.cols.usageMinDate') }}</label>
                                            <div class="fs-7 text-gray-800 mb-2" v-if="productForm.currentPackageItems[packageItemID].usage_min_date_initial"><b>{{ $t('common.system')}}:</b> {{ $moment(productForm.currentPackageItems[packageItemID].usage_min_date_initial).format("DD.MM.YYYY - HH:mm:ss") }}</div>
                                            <el-form-item prop="usage_min_date" class="mb-md-0 mb-2 mt-2" v-if="!packageItem.custom_usage_min_date_check">
                                                <el-date-picker
                                                    class="rangeDateTimePicker"
                                                    v-model="packageItem.custom_usage_min_date"
                                                    type="datetime"
                                                    popper-class="rangeDateTimePickerPopper"
                                                    :placeholder="$t('common.chooseDateAndTime')"
                                                    valueFormat="YYYY-MM-DD HH:mm:ss"
                                                    :disabledDate="(startDate) => disabledUsageMinDate(packageItem.custom_usage_max_date, startDate)">
                                                </el-date-picker>
                                            </el-form-item>
                                            <div class="mt-2 mb-1">
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" v-model="packageItem.custom_usage_min_date_check" :id="sprintf('package-usage-min-%d', [packageItemID])">
                                                    <label class="form-check-label" :for="sprintf('package-usage-min-%d', [packageItemID])">
                                                        {{ $t('pages.ecommerce.order.save.product.usageMinDateCheckText') }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 fv-row mb-1">
                                            <label class="fs-6 fw-bold">{{ $t('pages.ecommerce.order.save.product.cols.usageMaxDate') }}</label>
                                            <div class="fs-7 text-gray-800 mb-2" v-if="productForm.currentPackageItems[packageItemID].usage_max_date_initial"><b>{{ $t('common.system')}}:</b> {{ $moment(productForm.currentPackageItems[packageItemID].usage_max_date_initial).format("DD.MM.YYYY - HH:mm:ss") }}</div>
                                            <el-form-item prop="usage_max_date" class="mb-0 mt-2" v-if="!packageItem.custom_usage_max_date_check">
                                                <el-date-picker
                                                    class="rangeDateTimePicker"
                                                    v-model="packageItem.custom_usage_max_date"
                                                    type="datetime"
                                                    popper-class="rangeDateTimePickerPopper"
                                                    :placeholder="$t('common.chooseDateAndTime')"
                                                    valueFormat="YYYY-MM-DD HH:mm:ss"
                                                    :disabledDate="(endDate) => disabledUsageMaxDate(packageItem.custom_usage_min_date, endDate)">
                                                </el-date-picker>
                                            </el-form-item>
                                            <div class="mt-2 mb-1">
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" v-model="packageItem.custom_usage_max_date_check" :id="sprintf('package-usage-max-%d', [packageItemID])">
                                                    <label class="form-check-label" :for="sprintf('package-usage-max-%d', [packageItemID])">
                                                        {{ $t('pages.ecommerce.order.save.product.usageMaxDateCheckText') }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="fv-row mb-7" v-if="packageItem.addableAddons.length">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.order.save.product.addon.addableAddons') }}</label>
                                        <el-select class="w-100" :placeholder="$t('common.chooseSelect')">
                                            <el-option v-for="(addableAddon, addableAddonIndex) in packageItem.addableAddons" :key="addableAddonIndex" :value="addableAddonIndex" :label="addableAddon.title" @click="selectedPackageItemAddableAddon(packageItemID, addableAddonIndex)"></el-option>
                                        </el-select>
                                    </div>

                                    <div class="fv-row mb-3" v-for="(addon, addonIndex) in packageItem.addonForm" :key="addonIndex">
                                        <div class="d-flex align-items-center mb-2">
                                            <label class="fs-6 fw-bold">{{ sprintf($t('pages.ecommerce.order.save.product.addon.addonPattern'), [(addonIndex + 1), addon.title]) }}</label>
                                            <a class="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px ms-3" v-on:click="removePackageItemAddableAddon(packageItemID, addonIndex)" v-if="addon.removable">
                                                <span class="svg-icon svg-icon-3">
                                                    <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                                </span>
                                            </a>
                                        </div>
                                        <el-input-number v-model="addon.quantity" :min="1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-loader bg-dark bg-opacity-5" v-if="productForm.loader">
                            <span class="spinner-border text-primary"></span>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button class="btn btn-lg btn-primary" type="submit" :disabled="productForm.loader">
                            <span class="indicator-label">
                                {{ $t("btn.save") }}
                                <span class="svg-icon svg-icon-3 ms-2 me-0">
                                    <inline-svg src="icons/duotune/arrows/arr064.svg"/>
                                </span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "save",
    data() {
        return {
            user: {
                filterable: true,
                loading: false,
                list: [],
                addressList: []
            },
            productForm: {
                currentProduct: {},
                title: "",
                addableAddons: [],
                packageItemsAddons: {},
                currentPackageItems: {},
                loader: false,
                data: {
                    addons: [],
                    package_items: []
                }
            },
            form: {
                loading: false,
                data: {
                    items: []
                }
            },
            responseMessages: {
                package_internal_no_item: "packageInternalNoItem"
            },
        }
    },
    computed: {
        userID() {
            return this.$route.query.userID;
        },
        products() {
            let products = this.$store.state.ecommerce.productManagement.product.indexRaw.table.data;
            let addableProducts = [];

            for (let i = 0; i < products.length; i++) {
                let product = products[i];
                product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id')

                /*if (product.stock > product.sold_out_count) {
                    addableProducts.push(product);
                }*/
                addableProducts.push(product);
            }

            return addableProducts;
        },
        orderStatus() {
            return this.$store.state.ecommerce.order.status.table.data;
        }
    },
    mounted() {
        this.$store.dispatch('ecommerce/order/status/get', {
            page: {pageSize: 9999}
        });

        this.$store.dispatch('ecommerce/productManagement/product/indexRaw/get', {
            page: { pageSize: 9999 },
        });

        if (this.userID > 0) {
            this.loadUser();
        }
    },
    methods: {
        onSubmit() {
            this.$refs.orderForm.validate((valid) => {
                if (valid) {
                    if (this.prepareOrderFormData()) {
                        this.form.loading = true;

                        this.axios.post(this.endpoints['ecommerce_order'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.$router.push({
                                    path: "/ecommerce/order-management/order/detail/" + response.data.data.id
                                });
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            if(this.responseMessages[error.response.data.message]) {
                                error.response.data.message = this.$t('pages.ecommerce.order.save.responseMessages.' + this.responseMessages[error.response.data.message]);
                            }

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareOrderFormData() {
            if (!(this.form.data.items.length > 0)) {
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.ecommerce.order.save.warnings.items"),
                })

                return false;
            }

            return true;
        },
        remoteSearchUser(query) {
            if (query) {
                this.user.loading = true;
                this.axios.get(this.endpoints['user'], {
                    params: {
                        magic: query
                    }
                }).then(response => {
                    let data = response.data.data;
                    this.user.list = data;
                }).finally(() => {
                    this.user.loading = false;
                });
            } else {
                this.user.list = [];
            }
        },
        loadUser() {
            this.user.filterable = false;

            this.axios.get(this.endpoints['user'] + '/' + this.userID).then(response => {
                let data = response.data.data;
                this.user.list = [data];
                this.form.data.user_id = parseInt(this.userID);
                this.selectedUser();
            }).catch(() => {
                this.user.filterable = true;
            });
        },
        selectedUser() {
            if (this.form.data.user_id) {
                this.axios.get(this.endpoints['user_address'], {
                    params: {
                        user_id: this.form.data.user_id
                    }
                }).then(response => {
                    let data = response.data.data;
                    this.user.addressList = data;
                })
            }
        },
        newProduct() {
            this.productForm.addableAddons = [];
            this.productForm.packageItemsAddons = {};
            this.productForm.currentPackageItems = {};
            this.productForm.currentProduct = {};
            this.productForm.data = {
                quantity: 1,
                usage_min_date_check: true,
                usage_max_date_check: true,
                addons: [],
                package_items: []
            };
            this.productForm.title = this.$t('pages.ecommerce.order.save.product.newProduct');
            this.showModal(this.$refs.addProductModal);
        },
        onSubmitProduct() {
            this.$refs.productForm.validate((valid) => {
                if (valid) {
                    let productData = this.prepareProductFormData();
                    this.form.data.items.push(productData);
                    this.hideModal(this.$refs.addProductModal);
                } else {
                    return false;
                }
            });
        },
        prepareProductFormData() {
            let productData = JSON.parse(JSON.stringify(this.productForm.data))
            productData.title = this.productForm.currentProduct.translate.title;

            productData.usage_min_date = productData.usage_min_date_check ? undefined : (productData.usage_min_date ?? null);
            productData.usage_max_date = productData.usage_max_date_check ? undefined : (productData.usage_max_date ?? null);

            if (Object.keys(this.productForm.packageItemsAddons).length) {
                for (let packageItemID in this.productForm.packageItemsAddons) {
                    let packageItemDataObj = {
                        id: packageItemID,
                    };

                    let packageItem = this.productForm.packageItemsAddons[packageItemID];

                    if (packageItem.addonForm.length) {
                        packageItemDataObj.addons = packageItem.addonForm;
                    }

                    packageItemDataObj.custom_usage_min_date = packageItem.custom_usage_min_date_check ? undefined : (packageItem.custom_usage_min_date ?? null);
                    packageItemDataObj.custom_usage_max_date = packageItem.custom_usage_max_date_check ? undefined : (packageItem.custom_usage_max_date ?? null);

                    let clonePackageItemDataObj = this.cloneData(packageItemDataObj);
                    if(Object.keys(clonePackageItemDataObj).length > 1) {
                        productData.package_items.push(clonePackageItemDataObj);
                    }
                }
            }

            return productData;
        },
        selectedProduct() {
            this.productForm.addableAddons = [];
            this.productForm.data.addons = [];
            this.productForm.packageItemsAddons = {};

            if (this.productForm.data.id) {
                this.productForm.loader = true;

                this.axios.get(this.endpoints['ecommerce_product'] + '/' + this.productForm.data.id).then((response) => {
                    this.productForm.loader = false;

                    let product = response.data.data;
                    product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id');

                    this.productForm.currentProduct = product;

                    if (product.addons_count > 0) {
                        [this.productForm.data.addons, this.productForm.addableAddons] = this.setProductFormAddons(product.addons);
                    }

                    if (product.package_items_count > 0) {
                        let packageItems = [];

                        for (let i = 0; i < product.package_items.length; i++) {
                            let packageItem = product.package_items[i];

                            if (packageItem.product.addons_count > 0) {
                                packageItems.push(packageItem);
                            }
                        }

                        if (packageItems.length) {
                            this.productForm.loader = true;

                            this.loadPackageItemsAddons(packageItems).then((result) => {
                                if (result.status) {
                                    this.productForm.packageItemsAddons = this.setProductFormPackageItems(packageItems, result.data);
                                } else {
                                    //console.log("eror_Hata", result.error);
                                    let hiddenFunction = () => {
                                        this.$refs.addProductModal.removeEventListener('hidden.bs.modal', hiddenFunction);

                                        this.$swal.fire({
                                            text: this.$t('pages.ecommerce.order.save.product.errors.occuredAnyProblem'),
                                            icon: "error",
                                            buttonsStyling: false,
                                            confirmButtonText: this.$t('btn.ok'),
                                            customClass: {
                                                confirmButton: "btn fw-bold btn-light-danger",
                                            },
                                        });
                                    };

                                    this.$refs.addProductModal.addEventListener('hidden.bs.modal', hiddenFunction);

                                    this.hideModal(this.$refs.addProductModal);
                                }
                            }).finally((response) => {
                                this.productForm.loader = false;
                            });
                        }
                    }
                });
            }
        },
        setProductFormAddons(addons) {
            let formAddons = [];
            let addableAddons = [];

            for (let i = 0; i < addons.length; i++) {
                let addon = addons[i];
                let addonTranslate = this.resolveDatum(addon.product.translations, this.$root.defaultLanguage.id, 'language_id')

                let obj = {
                    id: addon.id,
                    title: addonTranslate.title,
                    quantity: 1
                };

                if (addon.auto_add_cart) {
                    obj.removable = !addon.cannot_remove_cart;
                    formAddons.push(obj);
                } else {
                    obj.removable = true;
                    addableAddons.push(obj);
                }
            }

            return [formAddons, addableAddons];
        },
        selectedAddableAddon(addableAddonIndex) {
            let addon = JSON.parse(JSON.stringify(this.productForm.addableAddons[addableAddonIndex]));
            this.productForm.data.addons.push(addon);

            this.productForm.addableAddons.splice(addableAddonIndex, 1);
        },
        removeAddableAddon(addonIndex) {
            let addon = JSON.parse(JSON.stringify(this.productForm.data.addons[addonIndex]));
            this.productForm.addableAddons.push(addon);

            this.productForm.data.addons.splice(addonIndex, 1);
        },
        async requestPackageItemsAddons(packageItemsRequest) {
            return new Promise((resolve, reject) => {
                this.axios.all(packageItemsRequest).then(this.axios.spread((...responses) => {
                    let result = [];

                    for (let i = 0; i < responses.length; i++) {
                        let handleResponse = responses[i].data;

                        if (!handleResponse.status) {
                            break;
                        }

                        result.push(handleResponse.data);
                    }

                    if (result.length == responses.length) {
                        resolve({status: true, data: result});
                    } else {
                        reject('request_count_dont_match_with_response_count');
                    }
                })).catch((errors) => {
                    reject('occured_any_error');
                })
            });
        },
        loadPackageItemsAddons(packageItems, retryCount = 2) {
            let packageItemsRequest = packageItems.map((packageItem) => {
                return this.axios.get(this.endpoints['ecommerce_product'] + '/' + packageItem.product_package_id)
            });

            return this.requestPackageItemsAddons(packageItemsRequest).then((response) => {
                return response;
            }).catch((error) => {
                return retryCount == 0 ? {status: false, error: error} : this.loadPackageItemsAddons(packageItems, (retryCount - 1));
            })
        },
        setProductFormPackageItems(packageItemList, responseData) {
            let packageItemsAddons = {};

            responseData.forEach((data, dataIndex) => {
                this.productForm.currentPackageItems[packageItemList[dataIndex].id] = data;

                let [addonForm, addableAddons] = this.setProductFormAddons(data.addons);
                packageItemsAddons[packageItemList[dataIndex].id] = {
                    title: this.resolveDatum(packageItemList[dataIndex].product.translations, this.$root.defaultLanguage.id, 'language_id').title,
                    custom_usage_min_date_check: true,
                    custom_usage_max_date_check: true,
                    addableAddons: addableAddons,
                    addonForm: addonForm
                };
            });

            return packageItemsAddons;
        },
        selectedPackageItemAddableAddon(packageItemID, addableAddonIndex) {
            let addon = JSON.parse(JSON.stringify(this.productForm.packageItemsAddons[packageItemID].addableAddons[addableAddonIndex]));
            this.productForm.packageItemsAddons[packageItemID].addonForm.push(addon);

            this.productForm.packageItemsAddons[packageItemID].addableAddons.splice(addableAddonIndex, 1);
        },
        removePackageItemAddableAddon(packageItemID, addonIndex) {
            let addon = JSON.parse(JSON.stringify(this.productForm.packageItemsAddons[packageItemID].addonForm[addonIndex]));
            this.productForm.packageItemsAddons[packageItemID].addableAddons.push(addon);

            this.productForm.packageItemsAddons[packageItemID].addonForm.splice(addonIndex, 1);
        },
        selectProductLabel(product){
           return this.sprintf(
               this.$t('pages.ecommerce.order.save.product.' + (product.stock > product.sold_out_count ? 'productLabelPattern' : 'noStockProductLabelPattern')),
               [product.translate.title, product.group.name]
           );
        },
        disabledUsageMinDate(maxUsageDate, startDate) {
            startDate = this.$moment(startDate);
            const endDate = maxUsageDate ? this.$moment(maxUsageDate) : undefined;

            return endDate != undefined && startDate.valueOf() > endDate.valueOf();
        },
        disabledUsageMaxDate(minUsageDate, endDate) {
            endDate = this.$moment(endDate);
            const startDate = minUsageDate ? this.$moment(this.$moment(minUsageDate).format('YYYY-MM-DD')) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        }
    }
}
</script>

<style>
.modal-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.rangeDateTimePickerPopper .el-button--text.el-picker-panel__link-btn {
    display: none;
}
</style>